import React from 'react';
import styles from './Footer.module.css';
import { Image } from 'react-bootstrap';
import logo_light_bg from '../../assets/images/logo_horizontal_light_bg.png';

export default function Footer() {
  return (
    <div>
      <hr className="page-break" />
      <div className={styles.footerContainer}>
        <Image src={logo_light_bg} height={50} />
        <p className={styles.copyright}>© 2024</p>
      </div>
    </div>
  );
}
