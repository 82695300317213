import React from 'react';
import styles from './DigitalVault.module.css';

export default function DigitalVault() {
  return (
    <div className={styles.digitalVaultWrapper} >
      <hr className="page-break" />
      <h1 className={`${styles.heading} poppins-bold ${styles.textBackground}`}>
        Your Referenceable Digital Vault
      </h1>
      <div className={styles.contentWrapper}id="digitalVault">
        <p className={`${styles.content} poppins-medium ${styles.textBackground}`}>
          Your secure memory vault, which we call Codex, neatly organizes all
          your interactions, turning them into a personalized knowledge
          repository. With Aurelius, your conversations gain depth and
          relevance. This smart memory system taps into Codex's extensive
          knowledge to ensure your Aurelius chats are always context-aware and
          meaningful. adventures.
        </p>
      </div>
      <br />
      <hr className="page-break" />
      <br />
      <h1 className={`${styles.heading} poppins-bold ${styles.textBackground}`}>
        Open Source Development
      </h1>
      <div className={styles.contentWrapper}id="openSource">
        <p className={`${styles.content2} poppins-medium ${styles.textBackground}`}>
          Moreover, Aurelius empowers developers to craft customized
          experiences, redefining the standards of human-AI interaction. Whether
          you're seeking to manage your information more efficiently or looking
          for a digital mind to assist you in recalling details effortlessly,
          Aurelius is here to transform your digital interactions into more
          intuitive and meaningful exchanges.
        </p>
      </div>
    </div>
  );
}
