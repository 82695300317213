import React from 'react';
import styles from './MemoryContent.module.css';

export default function MemoryContent() {
  return (
    <div className={`${styles.memoryContentWrapper}`} id="#memoryContent">
      <hr className="page-break" />
      <h1 className={`${styles.heading} poppins-bold ${styles.textBackground}`}>
        Your Memory and Content
      </h1>
      <div className={styles.contentWrapper}>
        <p className={`${styles.content} poppins-medium ${styles.textBackground}`}>
          Envision having every interaction, every burst
          of creativity, and every idea you've had, right at your fingertips,
          whenever you need them.
          <br />
          <br />
          Welcome to Aurelius, your digital mind, where personal computing is
          tailored to your unique experiences.
        </p>
      </div>
    </div>
  );
}
