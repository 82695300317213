import React from 'react';
import styles from './Hero.module.css';
import CTAModal from '../CTAModal/CTAModal';

export default function Hero() {
  return (
    <div className={styles.heroWrapper}>
      {/* <div className="col-md-6 col-sm-1 col-lg-12"> */}
      <div className={styles.textAnimationContainer}>
        <h1 className={`poppins-bold ${styles.textAnimationContainerH1}`}>
          Discover Aurelius
        </h1>
        <h2 className={`poppins-light ${styles.textAnimationContainerH2}`}>
          Personal Computing
        </h2>
        <h3 className={`poppins-medium ${styles.textAnimationContainerH3}`}>
          Redefined
        </h3>
      </div>
      <div className={styles.copyContainer}>
        <p className={`poppins-medium ${styles.textAnimationContainerP}`}>
          Meet Aurelius, more than a digital assistant—it's your evolving
          digital mind. Learning and growing with you, Aurelius captures your
          digital experiences, adapts to your lifestyle, and integrates
          seamlessly across all your devices.
        </p>
      </div>
      <div className={styles.btnContainer}>
        <CTAModal />
      </div>
    </div>
  );
}
