/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import particlesOptions from "./particles.json";
import NavBar from "./components/NavBar/NavBar";
import Hero from "./components/Hero/Hero";
import MemoryContent from "./components/MemoryContent/MemoryContent";
import DigitalMind from "./components/DigitalMind/DigitalMind";
import DigitalVault from "./components/DigitalVault/DigitalVault";
import Team from "./components/Team/Team";
import Footer from "./components/Footer/Footer";

function App() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    return (
        <>
            <NavBar />
            <div className="App container" >
                {init && <Particles options={particlesOptions} />}
                <Hero />
                <MemoryContent />
                <DigitalMind />
                <DigitalVault />
                <Footer />
            </div>
        </>
    );
}

export default App;
