import React from 'react';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import styles from './NavBar.module.css';
import logo from '../../assets/images/logo_horizontal_black.png';

export default function NavBar() {
  return (
    <Navbar expand="lg" className={styles.navbar} sticky="top" collapseOnSelect>
      <Container>
        <Navbar.Brand href="#">
          <Image src={logo} className={styles.navbarLogo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          className={styles.linksContainer}
          id="basic-navbar-nav"
        >
          <Nav className={`me-auto ${styles.navLinks}`}>
            <Nav.Link href="#digitalMind" className={styles.navItem}>
              Your Digital Mind
            </Nav.Link>
            <Nav.Link href="#digitalVault" className={styles.navItem}>
              Your Vault
            </Nav.Link>
            <Nav.Link href="#openSource" className={styles.navItem}>
              Open Source
            </Nav.Link>
            {/* Still waiting on Security copy */}
            {/* <Nav.Link href="#security" className={styles.navItem}>
              Security
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
