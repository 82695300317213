import React, { useState } from 'react';
import styles from './CTAModal.module.css';
import { Button, Form, Image, Modal } from 'react-bootstrap';
import logo from '../../assets/images/symbol_light_bg.png';


export default function CTAModal() {
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    comment: '',
  };
  const [show, setShow] = useState(false);
  const [formInput, setFormInput] = useState(initialState);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = e => {
    const { id, value } = e.target;
    setFormInput(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formInput)
    const fullName = `${formInput.firstName} ${formInput.lastName}`;
    const emailData = {
      fullName: fullName,
      email: formInput.email,
      message: formInput.comment,
    };
  
    // Make an API call to your Firebase Cloud Function
    fetch('https://us-central1-aurelius-website.cloudfunctions.net/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(emailData),
    })
      .then(response => {
        console.log(response);
        if (response.ok) {
          handleClose();
        } else {
          console.error('Error sending email');
        }
      })
      .catch(error => {
        console.error('Error sending email', error);
      });
  };
  return (
    <>
      <Button className={styles.getStartedBtn} onClick={handleShow}>
        <span>Ready to get started?</span>
        <br />
        <span>Contact us now!</span>
      </Button>

      <Modal
        className={styles.modal}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header closeButton>
          <Image src={logo} alt="Aurelius logo" height={'50'} />
          <Modal.Title className={styles.modalTitle}>
            Connect with us.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                id="firstName"
                value={formInput.firstName}
                onChange={handleChange}
                maxLength={50}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                id="lastName"
                value={formInput.lastName}
                onChange={handleChange}
                maxLength={50}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Email address <span style={{ color: 'var(--orange)' }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={formInput.email}
                onChange={handleChange}
                maxLength={50}
                required
              />
              {/* <Form.Text className="text-muted">
                View our privacy policy.
              </Form.Text> */}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="comment"
                value={formInput.comment}
                onChange={handleChange}
                maxLength={500}
              />
            </Form.Group>
            <Button
              className={styles.submitBtn}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Form>
          <p className={styles.requiredFieldsP}>
            <span style={{ color: 'var(--orange)' }}>*</span> required fields
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
