import React from 'react';
import styles from './DigitalMind.module.css';

export default function DigitalMind() {
  return (
    <div className={styles.digitalMindWrapper} id="digitalMind">
      <hr className="page-break" />
      <h1 className={`${styles.heading} poppins-bold ${styles.textBackground}`}>Your Digital Mind</h1>
      <div className={styles.contentWrapper}>
        <p className={`${styles.content} poppins-medium ${styles.textBackground}`}>
          Aurelius is your very own private Digital Mind & companion, designed
          to evolve and retain just like your mind.
          <br />
          <br />
          Imagine Struggling to remember that perfect New York diner from last
          fall? You comb through calendars, emails, and credit card statements,
          only to find frustration instead of the restaurant's name. Now imagine
          a different reality with Aurelius: simply ask, "Where did I have
          dinner last fall in NY?" and in moments, Aurelius provides the answer,
          transforming your frustrating search into a hassle-free experience. No
          digging through old records, just instant access to your past dining
          adventures.
        </p>
      </div>
    </div>
  );
}
